//Color
$blue: #2f4cda;
$green:#28cfd3;
$dark: #373737;
/*
==============================
Fonts
==============================
*/

$ff: "ProximaNova", sans-serif;
$ff-med: "ProximaNova-Medium", sans-serif;
$ff-heavy: "", sans-serif;
$ff-bold: "ProximaNova-Bold", sans-serif;
$ff-sec: "Korolev", sans-serif;
$ff-sec-heavy: "Korolev-Heavy", sans-serif;
$ff-leg: "LeagueGothic", sans-serif;
