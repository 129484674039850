@import url('https://fonts.googleapis.com/css?family=Josefin+Slab:600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700');

.b-index {
  /****  logo  ****/

  .b-top_logo {
    @include vw(
      $padding-top: 64
    );
    &_wrap{
      text-align: center;
      position: relative;
      @include vw(
        $padding-top: 33
      );
      .b-item{
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img{
          height: auto;
          position: relative;
          top: 0;
          transition: all .5s;
          @include vw(
                  $width: 132
          );
        }
        &.j-fixed{
          img{
            transition: all .5s;
            @include vw(
              $top: -130
            );
          }
        }
      }
      p{
        @include vw(
          $margin-top: 101,
          $font-size: 28
        );
      }
    }
  }

  /****  About  ****/

  .b-about {
    @include vw($padding: 14 0 0);
    @include respond-to(small) {
      padding: 60px 0;
      padding-top: 0;
    }
  ;
    &_icons {
      display: flex;
      justify-content: space-between;
      text-align: center;
      @include vw($padding: 95 0 121);
      @include respond-to(small) {
        flex-wrap: wrap;
        padding: 40px 0;
      }
      @include respond-to(713){
        justify-content: center;
      }
    ;
    }
    &_icon {
      @include vw($margin: 0 20 0);
      @include respond-to(small) {
        flex-basis: 200px;
        margin-bottom: 45px;
      }
    ;
      img {
        @include vw($width: 146,
        $margin: 0 auto 25);
        display: block;

      }
      figcaption {
        @include vw($font-size: 20);
      }
    }

    &_video {
      @include vw(
        $margin-top: 178
      );
      &_wrap {
        width: 100%;
        position: relative;
        margin: 0 auto;
        text-align: center;
        background-color: #2f4cda;
        //padding-bottom: 27%;
        @include vw(
          $padding-bottom: 88
        );
        @include respond-to(small) {
          width: 100%;
         // padding-bottom: 60%;
          padding-bottom: 50px;
          left: 0;
        }
      ;
        img, iframe {
          width: 100%;
          @include vw(
            $max-width: 500,
            $margin-top: -123,
            $box-shadow: 0 0 10 1 #333
          );

        }
        p{
          color: #fff;
          margin-bottom: 0;
        }
        a{
          background-color: #28cfd3;
          text-decoration: none;
          display: inline-block;
          color: #fff;
          width: 100%;
          @include transition();
          @include vw(
          $padding: 7 0,
            $max-width: 300,
            $border-radius: 4,
            $border: 2 solid #28cfd3,
            $margin-top: 20
          );
          &:hover,
          &:active,
          &:focus{
            background-color: #fff;
            color: #28cfd3;
          }
        }
      }
    }
    h1{
      @include vw(
        $font-size: 56
      );
      @include respond-to(small){
        font-size: 26px;
      }
    }
  }



  /****  blue  ****/

  .b-blue {
    background: $blue;
    color: #fff;
    @include vw($padding: 160 0 195);
    @include respond-to(small) {
      padding: 60px 0;
    }
  ;
    .l-title {
      color: #fff;
    }
    p {
      width: 76%;
      text-align: center;
      @include vw($margin: 65 auto 0,
      $font-size: 25);
      @include respond-to(small) {
        width: 90%;
      }
    ;
    }
  }

  /****  technology  ****/

  .b-technology {
    @include vw(
      $margin-top: 93
    );

    &_wrap{
      @include vw(
        $margin-top: 78
      );
      @include flex();
      flex-wrap: wrap;
      justify-content: space-between;
      &_item{
        width: 47%;
        background-color: #fcfcfc;
        @include vw(
          $border: 2 solid #ececec,
          $padding: 41 24,
          $border-radius: 4,
          $margin-bottom: 30
        );
        @include respond-to(600){
          width: 100%;
        }
      }
      @include respond-to(600){
        flex-direction: column;
      }
    }
  }

  /****  sign  ****/

  .b-sign {
    background: $green;
    color: #fff;
    text-align: center;
    font-family: $ff-bold;
    @include vw($padding: 123 0 105);
    @include respond-to(small) {
      padding: 60px 0;
    }
  ;
    h2 {
      @include vw(
              $font-size: 42,
              $letter-spacing: 2,
              $margin-bottom: 20);
      @include respond-to(small){
        font-size: 26px;
      }
    }
    p{
      margin-bottom: 0;
    }
    a {
      background: #fff;
      color: $green;
      font-family: $ff-bold;
      width: 100%;
      display: inline-block;
      transition: all 0.3s ease-in;
      @include vw($font-size: 30,
      $padding: 20,
      $max-width: 470,
      $border: 2 solid #fff,
      $border-radius: 7,
      $margin-top: 20
      );
      &:hover, &:active, &:focus {
        color: #fff;
        background: $green;
        @include vw(
                $border: 2 solid #fff);
      }
    }
  }
  .b-taking{
    background-image: url("/images/talking.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    @include vw(
      $padding: 324 0 349,
      $background-position: top  center
    );
    &_wrap{
      @include flex();
      justify-content: flex-end;
      &_item{
        text-align: center;
        width: 50%;
        position: relative;
        @include vw(
          $left: 16
        );
        p{
          line-height: 1.4;
          @include vw(
            $margin-top: 43,
            $font-size: 27,
            $padding-left: 36
          );
        }
        @include respond-to(small){
          left: 0;
          width: 100%;
        }
      }
    }
  }

}