@import "varibles";

/*
==============================
fonts
==============================
*/
@font-face {
  font-family: 'Korolev';
  src: url('/fonts/Korolev/Korolev-Medium.eot');
  src: url('/fonts/Korolev/Korolev-Medium?#iefix') format('embedded-opentype'),
  url('/fonts/Korolev/Korolev-Medium.woff') format('woff'),
  url('/fonts/Korolev/Korolev-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Korolev-Heavy';
  src: url('/fonts/Korolev/Korolev-Heavy.eot');
  src: url('/fonts/Korolev/Korolev-Heavy?#iefix') format('embedded-opentype'),
  url('/fonts/Korolev/Korolev-Heavy.woff') format('woff'),
  url('/fonts/Korolev/Korolev-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LeagueGothic';
  src: url('/fonts/LeagueGothic.otf') format('opentype'),
  url('/fonts/LeagueGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('/fonts/ProximaNova/ProximaNova-Regular.eot');
  src: url('/fonts/ProximaNova/ProximaNova-Regular?#iefix') format('embedded-opentype'),
  url('/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
  url('/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('/fonts/ProximaNova/ProximaNova-Bold.eot');
  src: url('/fonts/ProximaNova/ProximaNova-Bold?#iefix') format('embedded-opentype'),
  url('/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
  url('/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Medium';
  src: url('/fonts/ProximaNova/ProximaNova-Semibold.eot');
  src: url('/fonts/ProximaNova/ProximaNova-Semibold?#iefix') format('embedded-opentype'),
  url('/fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
  url('/fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  @include vw($font-size: 20);
  font-family: $ff;
  color: $dark;
}

p {
  @include vw($font-size: 20,
  $margin-bottom: 30);
  line-height: 1.6;
}

a {
  color: $green;
  transition: all 0.1s ease-in;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: $green;
    /*		text-decoration: underline;*/
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.g-site_width {
  @include vw($max-width: 1200);
  @include vw($padding: 0 15);
  width: 100%;
  margin: 0 auto;
  @include respond-to(small) {
    padding: 0 15px;
  }
;
}

/******* HEADER *******/
.g-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  &_icon {
    display: none;
    z-index: 99999999;
    position: absolute;
    right: 20px;
    top: 35px;
    @include respond-to(small) {
      display: block;
      width: 30px;
      height: 25px;
      margin: 10px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      float: right;
    }
  ;
    @include respond-to(xs) {
      margin: 10px 0;
    }
  ;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 50%;
      background: $blue;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(even) {
        left: 50%;
      }
      &:nth-child(odd) {
        left: 0;
      }
      &:nth-child(1),
      &:nth-child(2) {
        top: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        top: 11px;
      }
      &:nth-child(5),
      &:nth-child(6) {
        top: 22px;
      }
    }
    &.open {
      span {
        &:nth-child(1),
        &:nth-child(6) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(2),
        &:nth-child(5) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(1) {
          left: 5px;
          top: 9px;
        }
        &:nth-child(2) {
          left: calc(50% - 5px);
          top: 9px;
        }
        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }
        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }
        &:nth-child(5) {
          left: 5px;
          top: 14px;
        }
        &:nth-child(6) {
          left: calc(50% - 5px);
          top: 14px;
        }
      }
    }
  }
  &_top {
    @include respond-to(small) {
      position: relative;
      overflow: hidden;
    }
  ;
    .g-site_width {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      max-width: 96%;
      width: 100%;
      @include vw(
        //$max-width: 96%,
              $min-height: 90);
      @include respond-to(small) {
        min-height: 0;
        padding: 5px 15px;
      }
    ;
    }

  }
  &_nav {
    flex-basis: 35%;
    display: flex;
    justify-content: space-between;
    a {
      color: $blue;
      text-decoration: none;
      font-family: $ff-med;
      display: inline-block;
      @include vw($font-size: 16,
      $padding: 5 5 5 0,
      $letter-spacing: 1.3);
      @include respond-to(small) {
        font-size: 20px;
        padding: 15px 0;
        display: block;
        border-bottom: 1px solid $blue;
        padding-left: 15px;
        &:hover,
        &:focus,
        &:active {
          background-color: #2fa3da;
          color: #fff;
        }
      }
    ;
      &:last-child {
        display: none;
        @include respond-to(small) {
          display: block;
        }
      }
    }
    @include respond-to(small) {
      /*transition: all 0.7s ease-out;*/
      order: 8;
      flex-basis: 100%;
      flex-wrap: wrap;
      display: none;

      &.open {
        display: flex;
      }
    }
  ;
  }
  &_logo {
    display: flex;
    justify-content: center;
    flex-basis: 30%;
    text-align: center;
    @include respond-to(small) {
      text-align: left;
      order: 1;
      display: block;
    }
  ;
  }
  &_right {
    flex-basis: 35%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    @include respond-to(small) {
      flex-basis: 100%;
      order: 0;
      justify-content: flex-end;

    }
  ;
  }
  &_profile {
    //flex-basis: 31%;
    color: $blue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include vw($margin: 0 45 0 0);
    @include respond-to(small) {
      margin: 0;
      flex-basis: 140px;
      justify-content: flex-end;
    }
  ;
    &_img {
      border-radius: 50%;
      @include vw($width: 45,
      $height: 45,
      $margin-right: 10);
      @include respond-to(small) {
        width: 15px;
        height: 15px;
      }
    ;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    &_name {
      @include vw($font-size: 16,
      $padding-right: 25);
      position: relative;
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        top: 35%;
        @include vw($border-top: 6 solid $blue,
        $border-left: 5 solid transparent,
        $border-right: 5 solid transparent);
      }
    }
    &:hover {
      .g-header_profile_name {
        &:before {
          @include vw($border-top: 6 solid $green,
          $border-left: 5 solid transparent,
          $border-right: 5 solid transparent);
        }
      }
    }
  }
  &_cart {
    color: $blue;
    flex-basis: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include respond-to(small) {
      margin: 0;
      flex-basis: 140px;
      justify-content: flex-end;
    }
  ;
    &_img {
      @include vw($width: 25,
      $height: 25,
      $margin-right: 10);
      img {
        width: 100%;
      }
    }
    &_name {
      @include vw($font-size: 16,
      $padding-right: 25);
      position: relative;
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        top: 35%;
        @include vw($border-top: 6 solid $blue,
        $border-left: 5 solid transparent,
        $border-right: 5 solid transparent);
      }
    }
    &:hover {
      .g-header_cart_name {
        &:before {
          @include vw($border-top: 6 solid $green,
          $border-left: 5 solid transparent,
          $border-right: 5 solid transparent);
        }
      }
    }
  }
  &_bottom {
    background: $blue;
    @include vw($min-height: 60);
    @include transition();
    @include respond-to(small) {
      min-height: 20px;
    }
  ;

    &.j-top_scroll {
      @include transition();
      @include vw(
              $min-height: 3
      );
    }
  }
}
.g-header_line{
  width: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  transition: all .7s;
  z-index: 100;
  opacity: 0;
  @include vw(
    $border-bottom: 2 solid #2f4cda,
    $padding: 20 0 15,
    $top: -116
  );
  .g-logo{
    text-align: center;
    img{
      height: auto;
      @include vw(
              $width: 132
      );
    }
  }
  &.j-fixed{
    top: 0;
    opacity: 1;
  }
}

/******* FOOTER *******/

.g-footer {
  background: $dark;
  color: #fff;
  text-align: center;
  @include vw($padding: 75 0 30);
  @include respond-to(small) {
    padding: 30px 0 10px;
  }
;
  &_nav {
    nav {
      @include flex();
      flex-direction: column;
      a {
        color: #fff;
        @include vw(
                $padding: 3 5,
          $font-size: 20
        );
      }
    }

  }
  &_link {
    @include flex();
    justify-content: center;
    align-items: center;
    @include vw(
            $margin-bottom: 20
    );
    a {
      color: #fff;
      @include vw(
              $padding: 3 10
      );
    }
  }
  &_contact {
    @include vw(
            $margin-bottom: 100
    );
    display: flex;
    justify-content: space-between;
    @include respond-to(small) {
      margin-bottom: 30px;
    }
  ;
    @include respond-to(xs) {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  ;
  }
  &_col {
    flex-basis: 24%;
    text-align: center;
    p {
      @include vw($margin-bottom: 10);
    }
    @include respond-to(small) {
      flex-basis: 30%;
    }
  ;
    @include respond-to(xs) {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  ;

  }
  .l-title {
    color: #fff;
    @include vw(
            $font-size: 26
    );
    @include vw($margin-bottom: 40);
    @include respond-to(xs) {
      margin-bottom: 10px;

    }
  ;
  }
  p {
    color: #fff;
    @include vw($font-size: 16);
  }
  .r-color {
    color: #fff;
  }
  a {
    @include vw($font-size: 16);
    &:hover {
      opacity: 0.7
    }
  }
  &_copyright {
    @include vw(
      $font-size: 22,
      $padding-top: 15
    );
    color: #fff;
  }
}