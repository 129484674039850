.l-title{
	font-family: $ff-bold;
	@include vw($font-size: 30,
		$margin-bottom: 25);
	text-align: center;
	color: $blue;
	@include respond-to(small){
		font-size: 26px;
	};
}
.l-subtitle{
	@include vw($font-size: 25);
	text-align: center;
	color: $blue;
		@include respond-to(small){
		font-size: 18px;
	};
}

.l-margin_top{
    @include vw(
            $margin-top: 20
    );
}


/*********  modal   ***********/
.l-modal{
	.close{
	position: absolute;
	float: none;
	z-index: 9999;
	opacity: 1;
	@include vw($font-size: 55,
		$right: 10,
		$top: 10);
		&:hover{
			span{
		opacity: 1;
		color: $dark;	
	}
	}
	}


}
.l-modal{
		.modal-body {
		@include vw($padding: 50 30 30 );
		/*text-align: center;*/
	}
}